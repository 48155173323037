import React from "react";

import {
  Container,
  Layout,
} from "./page";
import { SEO } from "./metadata/index";


const ErrorPage = ({ error_code, error_desc }) => {

  return (
    <Layout>
      <SEO title={error_code} />
      <Container>
        <h1 class="text-3xl">{error_code}</h1>

        <p class="text-lg">{error_desc}</p>
        <p class="mt-8">If you have questions, please contact <u>help@junhan.xyz</u></p>
      </Container>
    </Layout>
  )
}

export default ErrorPage
