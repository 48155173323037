import React from "react";
import ErrorPage from "../components/ErrorPage";


const InternalErrorPage = () => {
  return (
    <ErrorPage
      error_code={"500: Internal Server Error"}
      error_desc={"Server Failure Occurred."}>
    </ErrorPage>
  );
}

export default InternalErrorPage;

